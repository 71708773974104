import React from 'react';
import '../Styling/Footer.css';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import MapIcon from '@material-ui/icons/Map';
import {Link} from 'react-router-dom';


function Footer() {
    return (
        <div className='footer'>
            <Link to='/'>
            <img className='footer_logo'
            src='/ImageAssets/santa-ana-logo.png'
            alt=''/>
            </Link>

            <div className='footer_socialLinks'>
                <a href='https://www.facebook.com/groups/135741500111/?ref=share' 
                className='icon' target='blank'>
                    <FacebookIcon/></a>
                    <a href='https://www.google.com/maps/place/2006+N+Broadway,+Santa+Ana,+CA+92706/@33.7629171,-117.87211,17z/data=!3m1!4b1!4m8!1m2!2m1!1sMtg.+Hall:+2006+N.+Broadway,+Santa+Ana,+CA+92706!3m4!1s0x80dcd9b11a627cf1:0x4940842675f1ec0d!8m2!3d33.7629127!4d-117.8699213'
                className='icon' target='blank'>
                    <MapIcon/></a>
                    <a href='https://instagram.com/christianstudentsatsac?igshid=1somhnqrons5m' 
                className='icon' target='blank'>
                    <InstagramIcon/></a>
            </div>

           <div className='footer_pageLinksWrapper'>
        {/* HOME */}
                 <Link to='/' className='footerLink'>
                    <div className='footerOption'>
                        <p>Home</p>
                    </div>
                </Link>
                <p>&ensp; | &ensp;</p>
        {/* OUR LIFE */}
                <Link to='/OurLife' className='footerLink'>
                    <div className='footerOption'>
                        <p>OurLife</p>
                    </div>
                </Link>
                <p>&ensp; | &ensp;</p>
        {/* BELIEF */}
                <Link to='/Belief' className='footerLink'>
                    <div className='footerOption'>
                        <p>Belief</p>
                    </div>
                </Link>
                <p>&ensp; | &ensp;</p>
        {/* MINISTRY */}
            <Link to='/Ministry' className='footerLink'>
                    <div className='footerOption'>
                        <p>Ministry</p>
                    </div>
                </Link>
                <p>&ensp; | &ensp;</p>
        {/* GOSPEL */}
                <Link to='/Gospel' className='footerLink'>
                    <div className='footerOption'>
                        <p>Gospel</p>
                    </div>
                </Link>
                <p>&ensp; | &ensp;</p>
        {/* VISIT */}
                <Link to='/Visit' className='footerLink'>
                    <div className='footerOption'>
                        <p>Visit</p>
                    </div>
                </Link>
                <p>&ensp; | &ensp;</p>
        {/* CONTACT */}
                <Link to='/Contact' className='footerLink'>
                    <div className='footerOption'>
                        <p>Contact</p>
                    </div>
                </Link>
                <p>&ensp; | &ensp;</p>
        {/* MEMBERS */}
                <Link to='/Members' className='footerLink'>
                    <div className='footerOption'>
                        <p>Members</p>
                    </div>
                </Link>
           </div>

            <p className='footer_contactInfo'>(714) 586-9294</p>
            <a href="mailto:someone@yoursite.com" className='footer_contactInfo'>thechurchinsantaana@gmail.com</a>
        </div>
    )
}

export default Footer;