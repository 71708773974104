import React, {useState} from 'react';
import "../Styling/Navbar.css";
import { NavLink } from "react-router-dom";
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

export default function Navbar() {
    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);
  return (
    <div>
      <nav className="navbar" id={click ? 'menuClicked':''}>
        <div className="nav_container">
          <NavLink exact to="/" className="nav_logo">
            <img
              className="nav_logoImg"
              src="/ImageAssets/santa-ana-logo.png"
              alt=""
            />
          </NavLink>

          <ul className={click ? 'nav_menu active':'nav_menu'}>
            <li className='nav_item'>
              <NavLink exact to="/" activeClassName="active" className="nav_links" onClick={() => setClick (false)}>
                Home
              </NavLink>
            </li>
            <li className='nav_item'>
              <NavLink exact to="/OurLife" activeClassName="active" className="nav_links" onClick={() => setClick (false)}>
                Our LIfe
              </NavLink>
            </li>
            <li className='nav_item'>
              <NavLink exact to="/Belief" activeClassName="active" className="nav_links" onClick={() => setClick (false)}>
                Belief
              </NavLink>
            </li>
            <li className='nav_item'>
              <NavLink exact to="/Ministry" activeClassName="active" className="nav_links" onClick={() => setClick (false)}>
                Ministry
              </NavLink>
            </li>
            <li className='nav_item'>
              <NavLink exact to="/Gospel" activeClassName="active" className="nav_links" onClick={() => setClick (false)}>
                Gospel
              </NavLink>
            </li>
            <li className='nav_item'>
              <NavLink exact to="/Visit" activeClassName="active" className="nav_links" onClick={() => setClick (false)}>
                visit
              </NavLink>
            </li>
            <li className='nav_item'>
              <NavLink exact to="/Contact" activeClassName="active" className="nav_links" onClick={() => setClick (false)}>
                Contact
              </NavLink>
            </li>
            <li className='nav_item'>
              <NavLink exact to="/Members" activeClassName="active" className="nav_links" onClick={() => setClick (false)}>
                Members
              </NavLink>
            </li>
          </ul>
          <div className='nav_icon' onClick = {handleClick}>
            <button>
                <MenuIcon id= {click ? 'hideIcon':'displayIcon'}/>
                <CloseIcon id= {click ? 'displayIcon':'hideIcon'}/>
            </button>
          </div>
        </div>
      </nav>
    </div>
  );
}
