import React from 'react';
import './Styling/Home.css';
import {SocialBox_Wrapper} from './Components/SocialBox.js';
import {Wrapper_div, Infobox_wrapper, Title, Body} from './Components/Infobox.js';

function Home() {
    return (
        <div className="home">
            <div className='home_hero'>
                <span className='home_heroText'>
                    <h1>Church in Santa Ana</h1>
                    <h3>Sundays at 10:00AM - 12:30AM</h3>
                    <h3>2006 N. Broadway, Santa Ana, CA 92706</h3>
                </span>
                <span className='home_testimonyWrapper'>
                    <p className='home_heroPar'>
                        The church in Santa Ana isn’t our name – it’s our description. As such, it’s an inclusive title, 
                        not an exclusive one. We gather together simply as believers of the Lord in the city of Santa Ana, 
                        and we receive as our brothers and sisters all who believe in Jesus Christ. Likewise, we warmly 
                        welcome guests and visitors who are not Christians.
                    </p>
                </span>
            </div>

            <div className='home_welcome'>
                <h1>WELCOME</h1>
                <p>The Church in Santa Ana <br/> welcomes you!</p>
                
                <SocialBox_Wrapper>
                    <a href='https://www.facebook.com/groups/135741500111/?ref=share'>
                        <img className='home_socialBox'
                        src='ImageAssets/facebookLogo.jpg'
                        alt='' />
                    </a>
                    <a href='https://goo.gl/maps/KJcEgUqdQ1RrSoB5A'>
                        <img className='home_socialBox'
                        src='/ImageAssets/mapsLogo.jpg'
                        alt='' />
                    </a>
                    <a href='https://www.instagram.com/christianstudentsatsac/?igshid=1somhnqrons5m'>
                        <img className='home_socialBox'
                        src='ImageAssets/instagramLogo.jpg'
                        alt='' />
                    </a>
                </SocialBox_Wrapper>
            </div>

            <span className='home_testimony'>
            <p>
                “It's wonderful to gather with believers regardless of age, language, culture, and ethnic 
                background in a place where Christ is the center and focus of all our activity.” 
            </p>
            </span>

            <Infobox_wrapper>
                <Wrapper_div backgroundColor = '#4297A0'>
                    <Title>Our Gatherings</Title>
                    <Body>
                    Lord’s day: Sunday 10:00am<br />
                    Youth Meetings: Saturday 6:00pm<br />
                    College gatherings: Nightly - various homes<br />
                    Prayer meetings: Tuesday 7:30pm
                    </Body>
                </Wrapper_div>
            
                <Wrapper_div backgroundColor = '#613659'>
                    <Title>Our Location</Title>
                    <Body>Mtg. Hall: 2006 N. Broadway, Santa Ana, CA 92706</Body>
                </Wrapper_div>
            </Infobox_wrapper>
        </div>
    )
}

export default Home;