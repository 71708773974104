import styled from "styled-components";

export const Hero_div = styled.div`
    background-color: #5B7581;
    background: url(${props => props.background}) no-repeat top center;
    width: 100%;
    min-height: 55vh;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
`;      

 export const Hero_title = styled.h1`
    margin-top: 10%;
    font-family: Fahkwang;
    font-size: 14vw;
    font-weight: 100;
`;

export const Hero_div_bkrndImage = styled.div`
    background-image: url('/ImageAssets/bibleHero.jpg');
    background-size: 100% 100%;
    width: 100%;
    min-height: 55vh;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
`; 