import React from 'react';
import {Hero_div_bkrndImage, Hero_title} from './Components/Hero.js';
import {Wrapper_div, Infobox_wrapper, Title, Body} from './Components/Infobox.js';

function Ministry() {
    return (
        <div className='ministry'>
            <Hero_div_bkrndImage>
                <Hero_title>MINISTRY</Hero_title>
            </Hero_div_bkrndImage>

            <Infobox_wrapper>
                <Wrapper_div backgroundColor = '#E57F84'>
                    <Title>“Fully accomplish your ministry." | 2 Timothy 4.5</Title>
                    <Body>God has placed you in the Body of Christ, and you have a function no one else has. As Christ's members, it's our privilege and responsibility to employ our function to build up His Church in love.</Body>
                    <br />
                    <Body>Here in the church in Santa Ana , we don't entrust this vital role to a pastor or any select group of persons. As Christ's fellow workers, we all work with Him and each other to develop the gift of grace that God has given each one of us for the carrying out of His purpose.</Body>
                </Wrapper_div>
                <Wrapper_div backgroundColor = '#2A6F97'>
                    <Title>"From every tribe and tongue." | Revelation 5.9</Title>
                    <Body>Ours is an open yet tightly knit community that comprises many races and three major language groups—English,and Spanish.</Body>
                    <br />
                    <Body>We believe that culture, language, and ethnicity should not be barriers within the household of the faith. Christ eliminated all enmity between cultural groups on His cross. It's this fact we seek to live out both in our daily life together and in our fellowship with, and support of, believers around the globe.</Body>
                </Wrapper_div>
                <Wrapper_div backgroundColor = '#52B69A'>
                    <Title>"Allow the little children to come to Me." | Luke 18.16</Title>
                    <Body>We take the Lord's loving command as our living commission. It's what motivates and sustains us in our care for our children.</Body>
                    <br />
                    <Body>We endeavor to obey the Lord's word—and support parents in this great responsibility—by providing our children with a nurturing atmosphere, a biblical and spiritual education, and wholesome fun and games.</Body>
                </Wrapper_div>
            </Infobox_wrapper>
        </div>
    )
}

export default Ministry;