import './App.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Footer from './Components/Footer.js';
import Navbar from './Components/Navbar.js';
import ScrollToTop from './Components/ScrollToTop.js';
import Home from './Home.js';
import OurLife from './OurLife.js';
import Belief from './Belief';
import Ministry from './Ministry';
import Gospel from './Gospel';
import Visit from './Visit';
import Contact from './Contact.js';
import Members from './Members.js';



function App() {
  return (
    <Router>
      <div className='app'>
      <ScrollToTop>
          <Switch>
            <Route exact path="/">
                <Navbar />
                <Home />
                <Footer />
            </Route>
            <Route path="/OurLife">
                <Navbar />
                <OurLife />
                <Footer />
            </Route>
            <Route path="/Belief">
                <Navbar />
                <Belief />
                <Footer />
            </Route>
            <Route path="/Ministry">
                <Navbar />
                <Ministry />
                <Footer />
            </Route>
            <Route path="/Gospel">
                <Navbar />
                <Gospel />
                <Footer />
            </Route>
            <Route path="/Visit">
                <Navbar />
                <Visit />
                <Footer />
            </Route>
            <Route path="/Contact">
                <Navbar />
                <Contact />
                <Footer />
            </Route>
            <Route path="/Members">
                <Navbar />
                <Members />
                <Footer />
            </Route>
          </Switch>
        </ScrollToTop>
      </div>
    </Router>
  );
}

export default App;