import React from 'react';
import './Styling/Visit.css';
import {Hero_div_bkrndImage, Hero_title} from './Components/Hero.js';
import {Wrapper_div, Infobox_wrapper, Title} from './Components/Infobox.js';

function Visit() {
    return (
        <div className='visit'>
            <Hero_div_bkrndImage background={`/ImageAssets/bibleHero.jpg`}>
                <Hero_title>VISIT</Hero_title>
            </Hero_div_bkrndImage>

            <Infobox_wrapper>
                <Wrapper_div backgroundColor = '#E57F84'>
                    <Title>VISIT US</Title>
                    <a href='https://goo.gl/maps/KJcEgUqdQ1RrSoB5A'>
                        <img className='mapLink'
                        src='/ImageAssets/mapsLogo.jpg'
                        alt='' />
                    </a>
                </Wrapper_div>
            </Infobox_wrapper>

            <div className='visit_schedule'>
                <h1>The Church In Santa Ana</h1>
                <p>
                Lord’s day: Sunday 10:00am <br />Church prayer meeting: Tuesday: 7:30pm<br />
                Young people’s Meeting: Saturday 6:00pm <br />Small Group’s: Friday various home<br />
                College gatherings: Nightly various homes<br /><br />
                Many other gatherings throughout the week.
                </p>
            </div>



        <div className="image_wrapper">
            <div className="image">
                <img
                    className="image_img"
                    src="/ImageAssets/children.JPG"
                    alt=""
                />
                <div className="image_overlay">
                    <p className="image_title">
                        CHILDREN'S
                    <br />
                        SERVICE
                    </p>
                    <p className="image_description">
                        Our toddlers and children are very precious to us. That's why we seek to help the little ones among us develop into young men and women who love Jesus Christ, know the Bible, and seek God’s kingdom first.
                    </p>
                    <a href='https://www.thebibletellsmeso.com/' className='image_button'>
                        Learn More
                    </a>
                </div>
            </div>
            <div className="image">
                <img
                    className="image_img"
                    src="/ImageAssets/collegeGroup.JPG"
                    alt=""
                />
                <div className="image_overlay">
                    <p className="image_title">
                        COLLEGE
                    <br />
                        GROUPS
                    </p>
                    <p className="image_description">
                        Want to be a strong young man or woman who's useful to the Master? Join one of our many family-hosted small groups near UCI and IVC and begin a grounded Christian life.
                    </p>
                    <a href='https://www.socalchristianstudents.com/' className='image_button'>
                        Learn More
                    </a>
                </div>
            </div>
            <div className="image">
                <img
                    className="image_img"
                    src="/ImageAssets/youngPeople.JPG"
                    alt=""
                />
                <div className="image_overlay">
                    <p className="image_title">
                        YOUNG PEOPLE'S
                    <br />
                        GATHERINGS
                    </p>
                    <p className="image_description">
                        It's up to everyone to look after the spiritual development of our next generation. We keep our middle school and high school students' lives meaningful by providing an atmosphere rich with God's Word, healthy companionships, and fun activities.
                    </p>
                </div>
            </div>
      </div>
    </div>
  );
}

export default Visit;