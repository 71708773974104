import React from 'react';
import './Styling/Contact.css';
import {Hero_div_bkrndImage, Hero_title} from './Components/Hero.js';
import {Title, Body} from './Components/Infobox.js';
import {SocialBox_Wrapper} from './Components/SocialBox.js';

function Contact() {
    return (
        <div className='contact'>
            <Hero_div_bkrndImage>
                <Hero_title>CONTACT</Hero_title>
            </Hero_div_bkrndImage>

            <div className='contact_schedule'>
                <div className='schedule_text'>
                    <p>Lord’s day: Sunday 10:00am <br />
                    Church prayer meeting: Tuesday: 7:30pm <br />
                    Young people’s Meeting: Saturday 6:00pm <br />
                    Small Group’s: Friday various home <br />
                    College gatherings: Nightly various homes
                    </p>
                    <h3>Many other gatherings throughout the week</h3>
                </div>
            </div>

            <SocialBox_Wrapper>
                    <a href='https://www.facebook.com/groups/135741500111/?ref=share'>
                        <img className='socialBox'
                        src='ImageAssets/facebookLogo.jpg'
                        alt='' />
                    </a>
                    <a href='https://goo.gl/maps/KJcEgUqdQ1RrSoB5A'>
                        <img className='socialBox'
                        src='/ImageAssets/mapsLogo.jpg'
                        alt='' />
                    </a>
                    <a href='https://www.instagram.com/christianstudentsatsac/?igshid=1somhnqrons5m'>
                        <img className='socialBox'
                        src='ImageAssets/instagramLogo.jpg'
                        alt='' />
                    </a>
            </SocialBox_Wrapper>

            <div className='contact_infoBox'>
                <Title>Have a question? we’d love to connect</Title>
                <Body>thechurchinsantaana@gmail.com <br />(714) 586 - 9294</Body>
            </div>
        </div>
    )
}

export default Contact;