import React from 'react';
import './Styling/Gospel.css';
import {Hero_div_bkrndImage, Hero_title} from './Components/Hero.js';
import {LinkBox, LikbBox_text} from './Components/Infobox.js';

function Gospel() {
    return (
        <div className='gospel'>
            <Hero_div_bkrndImage>
                <Hero_title>GOSPEL</Hero_title>
            </Hero_div_bkrndImage>

            <h1 className='gospel_pageTitle'>GENERAL CATEGORIES</h1>

            <div className='gospel_linkboxWrapper'>
                <LinkBox>
                    <LikbBox_text as="a" href='https://www.watchmannee.org/'>
                        Watchman Nee <br />(1903-1972)
                    </LikbBox_text>
                </LinkBox>
                <LinkBox>
                    <LikbBox_text as="a" href='https://www.witnesslee.org'>
                        Witness Lee <br />(1905-1997)
                    </LikbBox_text>
                </LinkBox>
                <LinkBox>
                    <LikbBox_text as="a" href='https://www.lordsmove.org/'>
                        Lord’s Move to Europe
                    </LikbBox_text>
                </LinkBox>
                <LinkBox>
                    <LikbBox_text as="a" href='https://www.livingstream.com/en/'>
                        Living Stream Ministry
                    </LikbBox_text>
                </LinkBox>
                <LinkBox>
                    <LikbBox_text as="a" href='https://www.recoveryversion.bible/'>
                    The Holy Bible Recovery Version
                    </LikbBox_text>
                </LinkBox>
                <LinkBox>
                    <LikbBox_text as="a" href='https://www.hymnal.net/en/home'>
                        Hymnals
                    </LikbBox_text>
                </LinkBox>
                <LinkBox>
                    <LikbBox_text as="a" href='https://beseeching.org/'>
                        Beseeching
                    </LikbBox_text>
                </LinkBox>
            </div>

            <h1 className='gospel_pageTitle'>STUDY TOOLS</h1>

            <div className='gospel_linkboxWrapper'>
                <LinkBox>
                    <LikbBox_text as="a" href='http://online.recoveryversion.org/'>
                        The New Testament Recovery version
                    </LikbBox_text>
                </LinkBox>
                <LinkBox>
                    <LikbBox_text as="a" href='https://www.biblesforamerica.org'>
                        Bibles For America
                    </LikbBox_text>
                </LinkBox>
                <LinkBox>
                    <LikbBox_text as="a" href='http://www.lsm.org/'>
                        Living Stream Ministry
                    </LikbBox_text>
                </LinkBox>
                <LinkBox>
                    <LikbBox_text as="a" href='http://www.ministrysamples.org/'>
                        The Living Word Of God Is Our Manna
                    </LikbBox_text>
                </LinkBox>
                <LinkBox>
                    <LikbBox_text as="a" href='http://ministrybooks.org/'>
                        Ministry Books
                    </LikbBox_text>
                </LinkBox>
            </div>
        </div>
    )
}

export default Gospel;