import React from 'react';
import {Hero_div_bkrndImage, Hero_title} from './Components/Hero.js';
import {Wrapper_div, Infobox_wrapper, Title, Body} from './Components/Infobox.js';

function Belief() {
    return (
        <div className='belief'>
            <Hero_div_bkrndImage>
                <Hero_title>BELIEF</Hero_title>
            </Hero_div_bkrndImage>

            <Infobox_wrapper>
                <Wrapper_div backgroundColor = '#00B4D8'>
                    <Title>THE BIBLE</Title>
                    <Body>The Bible is the complete divine revelation inspired word by word by God through the Holy Spirit.</Body>
                    <Body>2 Peter 1.21, 2 Timothy 3.16</Body>
                </Wrapper_div>
                <Wrapper_div backgroundColor = '#2A6F97'>
                    <Title>THE TRIUNE GOD</Title>
                    <Body>God is uniquely one, yet a Trinity—the Father, the Son, and the Spirit.</Body>
                    <Body>1 Timothy 2.5, Matthew 28.19</Body>
                </Wrapper_div>
                <Wrapper_div backgroundColor = '#52B69A'>
                    <Title>JESUS CHRIST</Title>
                    <Body>The Son of God, even God Himself, was incarnated to be a man by the name of Jesus Christ.</Body>
                    <Body>John 1.1, John 1.14</Body>
                </Wrapper_div>
                <Wrapper_div backgroundColor = '#E57F84'>
                    <Title>CHRIST'S CRUCIFIXION</Title>
                    <Body>Christ died on the cross for our sins, shedding His blood for our redemption.</Body>
                    <Body>1 Peter 2.24, Ephesians 1.7</Body>
                </Wrapper_div>
                <Wrapper_div backgroundColor = '#4297A0'>
                    <Title>CHRIST'S RESURRECTION</Title>
                    <Body>Christ resurrected from among the dead on the third day.</Body>
                    <Body>1 Corinthians 15.4</Body>
                </Wrapper_div>
                <Wrapper_div backgroundColor = '#613659'>
                    <Title>CHRIST'S ASCENSION</Title>
                    <Body>Christ ascended to the right hand of God to be Lord of all.</Body>
                    <Body>Acts 1.9, Acts 2.33, Acts 2.36</Body>
                </Wrapper_div>
                <Wrapper_div backgroundColor = '#AC203D'>
                    <Title>REGENERATION</Title>
                    <Body>Whenever any person repents to God and believes in the Lord Jesus Christ, he or she is regenerated (born again) and becomes a living member of the one Body of Christ.</Body>
                    <Body>Acts 20.21, John 3.3, Ephesians 1.22-23, Romans 12.5</Body>
                </Wrapper_div>
                <Wrapper_div backgroundColor = '#E1C340'>
                    <Title>CHRIST'S RETURN</Title>
                    <Body>Christ is coming again to receive His believers to Himself.</Body>
                    <Body>1 Thessalonians 2.19</Body>
                </Wrapper_div>
            </Infobox_wrapper>
        </div>
    )
}

export default Belief;