import styled from "styled-components";

 export const Title = styled.h3`
  font-size: 2.3rem;
  font-weight: 100;
  padding: 25px 10px;

  @media (max-width: 600px) {
    font-size: 2rem;
  }
 `;

 export const Body = styled.p`
  white-space: pre-wrap;
  text-align: center;
  padding: 0px 10%;
  padding-bottom: 25px;
  flex-wrap: wrap;
 `;

export const Wrapper_div = styled.div`
  width: 70vw;
  min-height: 31vh;
  border-radius: 30px;
  background-color: ${(props) => props.backgroundColor};
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 25px 0px;
`;

export const LinkBox = styled.div`
  width: 300px;
  height: 300px;
  background-color: #013A63;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  margin: 30px 30px;
  padding: 0 10px;
  `;

export const LikbBox_text = styled.a`
  text-decoration: none;
  color: white;
  font-size: 1.2rem;
  flex-wrap: wrap;
  text-align: center;
  white-space: pre-wrap;
`;

export const Infobox_wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;