import React from 'react';
import {Hero_div_bkrndImage, Hero_title} from './Components/Hero.js';
import {Wrapper_div, Infobox_wrapper, Title, Body} from './Components/Infobox.js';

function OurLife() {
    return (
        <div className='ourLife'>
            <Hero_div_bkrndImage>
                <Hero_title>OUR LIFE</Hero_title>
            </Hero_div_bkrndImage>
            
            <Infobox_wrapper>
                <Wrapper_div backgroundColor = '#013A63'>
                    <Title>The church</Title>
                    <Body>We maintain a vibrant and active life. Whether youre a growing family or a student, single or retired, we give you a place to live, grow, and serve as an indispensable member of Christ's Body.</Body>
                </Wrapper_div>
                <Wrapper_div backgroundColor = '#013A63'>
                    <Title>Small-group life</Title>
                    <Body>Gather with believers in your neighborhood for dinner, singing, Bible study, prayer, and daily fellowship. Dad, mom, the kids—everybody’s involved!</Body>
                </Wrapper_div>
                <Wrapper_div backgroundColor = '#013A63'>
                    <Title>Children's service</Title>
                    <Body>Our toddlers and children are very precious to us. That's why we seek to help the little ones among us develop into young men and women who love Jesus Christ, know the Bible, and seek God’s kingdom first.</Body>
                </Wrapper_div>
                <Wrapper_div backgroundColor = '#013A63'>
                    <Title>The Lord's supper and teaching</Title>
                    <Body>As believers in Christ who love and serve Him, we enjoy the Lord's supper each Lord's Day. Following our worship of the Father, we share with one another what we have enjoyed in the Scriptures in an open session of mutual teaching and encouragement.</Body>
                </Wrapper_div>
                <Wrapper_div backgroundColor = '#013A63'>
                    <Title>Spiritual companionship</Title>
                    <Body>Prayerful spiritual relationships of two or three believers fuel our Christian life and enable us to be Christ's witnesses. We value these companionships highly and take every opportunity to encourage,strengthen, and sustain them.</Body>
                </Wrapper_div>
                <Wrapper_div backgroundColor = '#013A63'>
                    <Title>Young people's gatherings</Title>
                    <Body>It's up to everyone to look after the spiritual development of our next generation. We keep our middle school and high school students' lives meaningful by providing an atmosphere rich with God's Word, healthy companionships, and fun activities.</Body>
                </Wrapper_div>
                <Wrapper_div backgroundColor = '#013A63'>
                    <Title>College groups</Title>
                    <Body>Want to be a strong young man or woman who's useful to the Master? Join one of our many family-hosted small groups near Santa Ana College and begin a grounded Christian life.</Body>
                </Wrapper_div>
                <Wrapper_div backgroundColor = '#013A63'>
                    <Title>Special events</Title>
                    <Body>Whether you're seven or seventy, we hold retreats, conferences, and special trainings that are designed for your spiritual growth and Christian advance.</Body>
                </Wrapper_div>
            </Infobox_wrapper>
        </div>
    )
}

export default OurLife;