import React, {useState} from 'react';
import './Styling/Members.css';
import {Hero_div, Hero_title} from './Components/Hero.js';
import {Wrapper_div, Infobox_wrapper, Title, Body} from './Components/Infobox.js';

function Members() {
    const [language, setLanguage] = useState(true)
    return (
        <div className='members'>
            <Hero_div background={'/ImageAssets/woodBkgrnd.jpg'}>
                <Hero_title>MEMBERS</Hero_title>
            </Hero_div>

            <span className='members_buttons'>
                <button className='langButton' onClick={()=>setLanguage(true)}>English</button>
                <button className='langButton' onClick={()=>setLanguage(false)}>Español</button>
            </span>

            <Infobox_wrapper id={language ? '' : 'hidden'}>
                <Wrapper_div backgroundColor = '#013A63'>
                    <Title>Upcoming Events</Title>
                        <span className='members_links'>
                            <a href='https://collegetraining.org/2021/'>College Trainings</a>
                            <a href='https://docs.google.com/forms/d/e/1FAIpQLSeWfCC7Wq2O2lvDa4nIP-SBOlAcE77hPVxOyG9ci_RY1GH0dw/closedform'>Summer school of truth</a>
                            <a href='https://drive.google.com/file/d/1PyrTz9RtwuWeyGeVwjQFzhbkToap_KO4/view?usp=sharing'>Special fellowship</a>
                        </span>
                    <Title>Meetings</Title>
                    <div className='meetings_textWrapper'>
                        <span className='members_links'>
                            <p className='meeting_subtitle'>English</p>
                            <a href='https://zoom.us/j/322586136?pwd=MWdBb01QdGIxOGNpRUJtRC9GUDlsUT09'>Lord's Day - 10am</a>
                            <a href='https://www.google.com/url?q=https://zoom.us/j/324673823?pwd%3DczBwOWNyd1dFRUR0UG01ZzRPdHdvZz09&sa=D&usd=2&usg=AOvVaw2-9_1YW6zbls882QKOBVkj'>Prayer meeting</a>
                            <a href='https://cccconfer.zoom.us/j/974487289?pwd=YmMzckloa0xCVUtPWjEvYlppcFg5dz09'>Neighborhood childrens meeting</a>
                            <a href='https://us02web.zoom.us/j/8449813238?pwd=OWVLall1UXVIYWsxZjduZ1F6YzB4UT09'>Saturday YP meeting</a>
                        </span>
                        <span className='members_links'>
                            <p className='meeting_subtitle'>Spanish</p>
                            <a href='https://zoom.us/j/790199030?pwd=KzhHYTlvdG1KS08rUldUODdWYm5BZz09'>Lord's Day</a>
                            <a href='https://us02web.zoom.us/j/8117896214?pwd=dDJibWN1VFppNUVHemNsNVRrNWdpUT09'>Sisters meeting</a>
                            <a href='https://zoom.us/j/995008617?pwd=R1NhS0NUNmtvR3VYd2p0NUc4bmpFdz09'>West direct meeting</a>
                            <a href='https://zoom.us/j/7406966727?pwd=aFMxRXp3ZVBOQmZNWElKMjFqb05DQT09'>East direct meeting</a>
                            <a href='https://us02web.zoom.us/j/8096788211?pwd=eWUyZ1ptUXhTRHg3eXZHWjdyRjNHZz09'>Bristol district meeting</a>
                        </span>
                    </div>
                </Wrapper_div>
            </Infobox_wrapper>

            <Infobox_wrapper id={language ? 'hidden' : ''}>
                <Wrapper_div backgroundColor = '#013A63'>
                    <Title>Eventos próximos</Title>
                        <span className='members_links'>
                            <a href='collegetraining.org'>Entrnamiento para jóvenes de edad del colegio</a>
                            <a href='https://docs.google.com/forms/d/e/1FAIpQLSeWfCC7Wq2O2lvDa4nIP-SBOlAcE77hPVxOyG9ci_RY1GH0dw/closedform'>Escuela de verano</a>
                            <a href='https://drive.google.com/file/d/1mbeSQg5mtOmxRoGTioMV9WVbj4U9lzsS/view'>Comunión especial</a>
                        </span>
                    <Title>Reuniones de la iglesia</Title>
                    <div className='meetings_textWrapper'>
                        <span className='members_links'>
                            <p className='meeting_subtitle'>Inglés</p>
                            <a href='https://zoom.us/j/322586136?pwd=MWdBb01QdGIxOGNpRUJtRC9GUDlsUT09#success'>Día del Señor</a>
                            <a href='https://zoom.us/j/322586136?pwd=MWdBb01QdGIxOGNpRUJtRC9GUDlsUT09#success'>Reunión de oración</a>
                            <a href='https://zoom.us/j/322586136?pwd=MWdBb01QdGIxOGNpRUJtRC9GUDlsUT09#success'>Reunión de vecindario para niños</a>
                            <a href='https://zoom.us/j/322586136?pwd=MWdBb01QdGIxOGNpRUJtRC9GUDlsUT09#success'>Reunión de jóvenes</a>
                        </span>
                        <span className='members_links'>
                            <p className='meeting_subtitle'>Español</p>
                            <a href='https://zoom.us/j/790199030?pwd=KzhHYTlvdG1KS08rUldUODdWYm5BZz09#success'>Día del Señor</a>
                            <a href='https://zoom.us/j/322586136?pwd=MWdBb01QdGIxOGNpRUJtRC9GUDlsUT09#success'>Reunión de hermanas</a>
                            <a href='https://zoom.us/j/322586136?pwd=MWdBb01QdGIxOGNpRUJtRC9GUDlsUT09#success'>Disrtitp Oeste</a>
                            <a href='https://zoom.us/j/322586136?pwd=MWdBb01QdGIxOGNpRUJtRC9GUDlsUT09#success'>Distritp Este</a>
                            <a href='https://zoom.us/j/322586136?pwd=MWdBb01QdGIxOGNpRUJtRC9GUDlsUT09#success'>Distrito Bristol</a>
                        </span>
                    </div>
                </Wrapper_div>
            </Infobox_wrapper>

            <span className='ministry_image_Wrapper' id={language ? '' : 'hidden'}>
                <img className='announcement_image'
                src='/ImageAssets/Announcements01.jpg'
                alt=''
                />
                <img className='announcement_image'
                src='/ImageAssets/Announcements02.jpg'
                alt=''
                />
            </span>
            <span className='ministry_image_Wrapper' id={language ? 'hidden' : ''}>
                <img className='announcement_image'
                src='/ImageAssets/Announcements-Spanish01.jpg'
                alt=''
                />
                <img className='announcement_image'
                src='/ImageAssets/Announcements-Spanish02.jpg'
                alt=''
                />
            </span>
        </div>
    )
}

export default Members;