import styled from "styled-components";

 export const SocialBox_Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 5%;

  @media (max-width: 455px) {
    flex-direction: column;
  }
`;